<template>
  <div class="payment-packages">
    <div
      class="packages-box packages-box-right-space btn-cursor"
      v-for="paymentPackage in packages"
      :key="paymentPackage.id"
      :class="{ 'package-active': selectedPackageId === paymentPackage.id }"
      @click="selectPackage(paymentPackage)"
    >
      <div
        class="packages-box-popular"
        v-show="paymentPackage.isLimitTimeDiscount"
      >
        Popular
      </div>
      <div class="packages-box-title-row">
        <div class="packages-box-title">
          {{ paymentPackage.name }}
        </div>
      </div>
      <div class="packages-box-price">
        <div class="packages-price">
          <span class="packages-price-symbol">$</span>
          <span class="packages-price-value">{{
            paymentPackage.unitPrice.price
          }}</span>
        </div>
        <span class="packages-price-separator">/</span>
        <span class="packages-price-time">{{
          paymentPackage.unitPrice.unit
        }}</span>
      </div>
      <div
        class="packages-box-save"
        v-show="paymentPackage.discountRatio"
        :class="{ 'color-red': paymentPackage.isLimitTimeDiscount }"
      >
        save
        {{ paymentPackage.discountRatio }}
      </div>
      <button
        type="button"
        class="
          packages-box-btn
          btn-radius-format-2 btn-border-format-2 btn-color-2 btn-cursor
        "
        :class="{ 'packages-box-btn-top-space': !paymentPackage.discountRatio }"
      >
        <span>{{ $t("index_choose") }}</span>
      </button>
      <div class="packages-box-tip">{{ paymentPackage.show.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentPackages",
  props: ["packageId"],
  data() {
    return {
      packages: [],
      selectedPackageId: 0,
    };
  },
  created() {
    if (this.packageId !== undefined && this.packageId.length !== 0) {
      this.selectedPackageId = parseInt(this.packageId);
    }
    this.getPackages();
  },
  methods: {
    getPackages() {
      this.$axios.get("topup-packages").then((response) => {
        this.packages = response.data;
        let selectedPackage = this.getPackageById(this.selectedPackageId);
        if (selectedPackage !== null) {
          this.$emit("updateSelectedPackage", selectedPackage);
        }
      });
    },
    selectPackage(paymentPackage) {
      this.$router.push({
        path: "/" + this.GLOBAL.currentLanguage + "/pricing",
        query: {
          packageId: paymentPackage.id,
        },
      });
      this.selectedPackageId = paymentPackage.id;
      this.$emit("updateSelectedPackage", paymentPackage);
    },
    getPackageById(packageId) {
      for (let index in this.packages) {
        if (this.packages[index].id === packageId) {
          return this.packages[index];
        }
      }
      return null;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.payment-packages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 380px;
}

.packages-box {
  width: 207px;
  height: 285px;
  padding: 17px 17px 31px 21px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0 0 5px rgba(39, 55, 61, 0.05);
  border-radius: 4px;
  transition: all 0.25s;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 36px;
  position: relative;
}

.packages-box-right-space {
  margin-left: 20px;
  margin-right: 20px;
}

.packages-box:hover {
  transform: translateY(-26px);
  opacity: 1;
  border-top: 6px solid #4ebe86;
  border-radius: 6px 6px 0 0;
}

.packages-box-title-row {
  width: inherit;
  height: 54px;
  display: flex;
  justify-content: space-between;
}

.packages-box-title {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
  opacity: 1;
  text-transform: uppercase;
}

.packages-box-popular {
  padding: 0 15px;
  line-height: 24px;
  background: #4ebe86;
  font-size: 11px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 13px;
  position: absolute;
  top: -12px;
  left: 21px;
}

.packages-box-price {
  width: inherit;
  display: flex;
  align-items: center;
  color: #1f1f1f;
}

.packages-price {
  display: flex;
  align-items: flex-start;
}

.packages-price-symbol {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  color: #1f1f1f;
  opacity: 1;
}

.packages-price-value {
  font-size: 45px;
  font-weight: 400;
  line-height: 54px;
  color: #1f1f1f;
  opacity: 1;
  margin: 0 4px;
}

.packages-price-separator {
  margin: 0 6px;
  font-weight: bold;
}

.packages-price-time {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #1f1f1f;
  opacity: 1;
}

.packages-box-save {
  width: inherit;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  opacity: 1;
  margin-left: 21px;
  margin-top: 10px;
}

.packages-box-btn-top-space {
  margin-top: 99px;
}

.packages-box-btn {
  width: 147px;
  height: 36px;
  margin-top: 69px;
}

.packages-box-btn span {
  font-size: 18px;
  font-weight: 400;
}

.packages-box-tip {
  width: inherit;
  font-size: 10px;
  font-weight: 200;
  color: #a8a8a8;
  opacity: 1;
  margin-top: 30px;
  line-height: 24px;
}

.color-red {
  color: #ff0000;
}

.package-active {
  border: 2px solid #4ebe86;
  background: rgba(78, 190, 134, 0.05);
  border-top: 6px solid #4ebe86;
  border-radius: 6px 6px 0 0;
}

.package-active .packages-box-btn {
  background: #4ebe86;
  color: #ffffff;
  border-color: transparent;
}

.package-active .packages-box-popular {
  top: -15px;
}

.packages-box:hover .packages-box-popular {
  top: -15px;
}
</style>
