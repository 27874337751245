<template>
  <div class="pricing">
    <Header />
    <div class="platform-box">
      <div class="platform-content-box">
        <div class="step-1-left">
          <div class="step-title-row">
            <div class="step-btn">step1</div>
            <div class="step-title"><h1>{{ $t("pricing_step1_title") }}</h1></div>
          </div>
          <div class="step-tip">
            {{ $t("pricing_step1_subtitle") }}
          </div>
          <div class="platform-row">
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=0'}`"
            >
              <img
                src="../assets/icons/pricing-android.svg"
                class="platform-icon"
              />
            </router-link>
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=1'}`"
            >
              <img
                src="../assets/icons/pricing-windows.svg"
                class="platform-icon"
              />
            </router-link>
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=2'}`"
            >
              <img
                src="../assets/icons/pricing-mac.svg"
                class="platform-icon"
              />
            </router-link>
            <div class="vertical-line"></div>
            <div class="platform-ios-box">
              <img
                src="../assets/icons/pricing-ios.svg"
                class="platform-icon"
                @mouseleave="showOrHideDownloadIosTip(0)"
                @mouseover="showOrHideDownloadIosTip(1)"
              />
              <div class="download-ios-tip" v-show="isShowOrHideDownloadIosTip">
                <span>coming soon...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="step-1-right">
          <img
            alt="shell guarantee"
            src="../assets/image/pricing_7days.svg"
            class="days-icon"
          />
          <div class="step-1-right-title">
            {{ $t("pricing_money_back_guarantee") }}
          </div>
        </div>
      </div>
    </div>
    <div class="plan-area">
      <PaymentPackage
        @updateSelectedPackage="updateSelectedPackage"
        ref="paymentPackageChild"
        :packageId="this.packageId"
      ></PaymentPackage>
      <div class="coupon-container">
        <div class="coupon-title" @click="showCouponInput">
          {{ $t("pricing_step1_have_coupon") }}
        </div>
        <div class="input-box input-space" v-show="isShowCouponInput">
          <div class="input-tip" v-if="coupon">
            {{ $t("pricing_step1_enter_coupon") }}
          </div>
          <el-input
            tabindex="1"
            class="input-email"
            v-model="coupon"
            :placeholder="$t('pricing_step1_enter_coupon')"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="step-2" v-show="!this.checkAuth()">
      <div class="step-2-content-box">
        <div class="step-title-row">
          <div class="step-btn">step2</div>
          <div class="step-title"><h2>{{ $t("pricing_step2_title") }}</h2></div>
        </div>
        <div class="step-tip step-2-tip">
          {{ $t("pricing_step2_subtitle") }}
        </div>
        <div class="create-account-input-box">
          <el-input
            tabindex="2"
            type="text"
            v-model="email"
            :placeholder="$t('pricing_step2_email')"
          >
          </el-input>
        </div>
        <!-- <div class="email-tip" v-if="email">
          We will not share your information or send spam
        </div> -->
        <div class="create-account-input-box">
          <el-input
            :key="passwordType"
            ref="password"
            v-model="password"
            :type="passwordType"
            :placeholder="$t('pricing_step2_password')"
            name="password"
            tabindex="3"
            auto-complete="on"
            @keyup="checkPassword"
          >
            <template #suffix>
              <span class="show-or-hide-password" @click="showOrHidePassword">
                <img
                  class="show-or-hide-pwd-icon"
                  :src="
                    passwordType === 'password'
                      ? require('../assets/icons/form_hide_pwd.svg')
                      : require('../assets/icons/form_show_pwd.svg')
                  "
                />
              </span>
            </template>
          </el-input>
        </div>
        <div class="password-tip">
          <div>
            <span>{{ $t("pricing_step2_password_length") }}</span>
            <img
              class="icon-correct"
              src="../assets/icons/pricing-correct.svg"
              v-show="passwordCheckLengthCorrect"
            />
          </div>
          <div>
            <span>{{ $t("pricing_step2_contain_letters") }}</span>
            <img
              class="icon-correct"
              src="../assets/icons/pricing-correct.svg"
              v-show="passwordCheckContainLettersCorrect"
            />
          </div>
          <div>
            <span>{{ $t("pricing_step2_include_figures") }}</span>
            <img
              class="icon-correct"
              src="../assets/icons/pricing-correct.svg"
              v-show="passwordCheckIncludeFiguresCorrect"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="step-3">
      <div class="step-3-box">
        <div class="step-title-row">
          <div class="step-btn">{{ this.checkAuth() ? "step2" : "step3" }}</div>
          <div class="step-title"><h3>{{ $t("pricing_step3_title") }}</h3></div>
        </div>
        <div class="step-3-content-box">
          <div class="payment">
            <div class="payment-box">
              <!-- <div
                class="
                  payment-item payment-item-right-space payment-item-left-space
                  btn-cursor
                "
                @click="changePaymentMethod('yuansfer')"
                :class="{
                  'selected-payment-method':
                    currentPaymentMethod === 'yuansfer',
                }"
              >
                <img
                  alt="alipay"
                  src="../assets/image/pricing_payment_alipay.svg"
                  class="payment-icon-alipay"
                />
              </div> -->
              <div
                class="
                  payment-item payment-item-right-space payment-item-left-space
                  btn-cursor
                "
                @click="changePaymentMethod('paypal')"
                :class="{
                  'selected-payment-method': currentPaymentMethod === 'paypal',
                }"
              >
                <img
                  alt="paypal"
                  src="../assets/image/pricing_payment_paypal.svg"
                  class="payment-icon-paypal"
                />
              </div>
              <div
                class="payment-item-card btn-cursor"
                @click="changePaymentMethod('stripe')"
                :class="{
                  'selected-payment-method': currentPaymentMethod === 'stripe',
                }"
              >
                <img
                  alt="card"
                  src="../assets/image/pricing_payment_stripe.svg"
                  class="payment-icon-card"
                />
              </div>
            </div>
            <div class="payment-product-line"></div>
            <div class="order-box">
              <div
                class="
                  order-info-line
                  order-info-line-top-space
                  order-info-line-bottom-space
                "
              >
                <span>{{ selectedPackage.name }}</span>
                <span>{{ originalTotalPrice }}$</span>
              </div>
              <div class="order-info-line order-info-line-bottom-space">
                <span>{{ $t("pricing_step3_discount") }}</span>
                <span class="color-red">{{ discountTotalPrice }}$</span>
              </div>
              <div class="order-info-line order-info-line-bottom-space">
                <span class="text-bold">{{
                  $t("pricing_step3_order_totla")
                }}</span>
                <span class="order-total">{{ selectedPackage.price }}$</span>
              </div>
            </div>
            <div class="purchase-tip">
              By submitting, you agree to our
              <router-link
                :to="`${'/' + this.GLOBAL.currentLanguage + '/privacy_policy'}`"
                ><span> Privacy Policy</span>
              </router-link>
              And
              <router-link
                :to="`${'/' + this.GLOBAL.currentLanguage + '/term'}`"
                ><span> Terms of Service</span>
              </router-link>
            </div>
            <button
              type="button"
              class="confirm-btn btn-radius-format-1 btn-color-1 btn-cursor"
              @click="confirmPayment()"
              :disabled="this.duringPayment"
              :class="{ 'btn-not-allowed': this.duringPayment }"
            >
              <span>{{ $t("pricing_confirm_payment") }}</span>
            </button>
          </div>
          <div class="premium-includes">
            <div class="premium-includes-title">
              {{ $t("pricing_premium_includes") }}
            </div>
            <div>
              <div class="premium-includes-line">
                <div>
                  <img
                    class="icon-correct"
                    src="../assets/icons/pricing-correct.svg"
                  />
                </div>
                <div class="premium-includes-description">
                  {{ $t("pricing_advanced") }}
                </div>
              </div>
              <div class="premium-includes-line">
                <div>
                  <img
                    class="icon-correct"
                    src="../assets/icons/pricing-correct.svg"
                  />
                </div>
                <div class="premium-includes-description">
                  {{ $t("pricing_optimized") }}
                </div>
              </div>
              <div class="premium-includes-line">
                <div>
                  <img
                    class="icon-correct"
                    src="../assets/icons/pricing-correct.svg"
                  />
                </div>
                <div class="premium-includes-description">
                  {{ $t("pricing_locations") }}
                </div>
              </div>
              <div class="premium-includes-line">
                <div>
                  <img
                    class="icon-correct"
                    src="../assets/icons/pricing-correct.svg"
                  />
                </div>
                <div class="premium-includes-description">
                  {{ $t("pricing_streaming_with_unlimited_data") }}
                </div>
              </div>
              <div class="premium-includes-line">
                <div>
                  <img
                    class="icon-correct"
                    src="../assets/icons/pricing-correct.svg"
                  />
                </div>
                <div class="premium-includes-description">
                  {{ $t("pricing_live_support") }}
                </div>
              </div>
              <div class="premium-includes-line">
                <div>
                  <img
                    class="icon-correct"
                    src="../assets/icons/pricing-correct.svg"
                  />
                </div>
                <div class="premium-includes-description">
                  {{ $t("pricing_devices") }}
                </div>
              </div>
            </div>
            <img
              alt=""
              src="../assets/image/pricing_stars.svg"
              class="icon-pricing-star"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import PaymentPackage from "@/components/PaymentPackage";

export default {
  name: "Pricing",
  components: { Header, Footer, PaymentPackage },
  data() {
    return {
      duringPayment: false,
      packageId: 0,
      isShowEmailTip: false,
      isShowCouponInput: false,
      isShowOrHideDownloadIosTip: false,
      coupon: null,
      email: null,
      password: null,
      passwordType: "password",
      passwordCheckLengthCorrect: false,
      passwordCheckContainLettersCorrect: false,
      passwordCheckIncludeFiguresCorrect: false,
      currentPaymentMethod: "yuansfer",
      selectedPackage: {
        isMock: true,
        id: 17,
        name: "Annual Special Sale",
        deviceCount: 5,
        currency: "USD",
        price: "48.89",
        unitPrice: {
          price: "2.03",
          unit: "month",
        },
        isLimitTimeDiscount: true,
        discountRatio: "80%",
        options: [],
        show: {
          time: "+ 12 Months",
          timeFree: "Annual Special Sale",
          originalPrice: "239.76",
          description: "Billed $48.89 for 24 months",
        },
      },
    };
  },
  computed: {
    originalTotalPrice() {
      if (this.selectedPackage.show.originalPrice) {
        return this.selectedPackage.show.originalPrice;
      }
      if (this.selectedPackage.discountRatio) {
        return (
          (this.selectedPackage.price /
            (100 - this.selectedPackage.discountRatio.replace("%", ""))) *
          100
        ).toFixed(2);
      }
      return this.selectedPackage.price;
    },
    discountTotalPrice() {
      return (
        "-" + (this.originalTotalPrice - this.selectedPackage.price).toFixed(2)
      );
    },
  },
  created() {
    this.packageId =
      this.$route.query.packageId === undefined
        ? 0
        : this.$route.query.packageId;
  },
  methods: {
    showOrHideDownloadIosTip(value) {
      this.isShowOrHideDownloadIosTip = !!value;
    },
    showOrHidePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    changePaymentMethod(value) {
      this.currentPaymentMethod = value;
    },
    checkPassword() {
      this.passwordCheckLengthCorrect =
        this.password !== null &&
        this.password.length >= 8 &&
        this.password.length <= 16;
      let regCheckContainFigures = new RegExp(/[0-9]+/);
      this.passwordCheckIncludeFiguresCorrect = regCheckContainFigures.test(
        this.password
      );
      let regCheckContainLetters = new RegExp(/[A-Za-z]+/);
      this.passwordCheckContainLettersCorrect = regCheckContainLetters.test(
        this.password
      );
      return (
        this.passwordCheckLengthCorrect &&
        this.passwordCheckIncludeFiguresCorrect &&
        this.passwordCheckContainLettersCorrect
      );
    },
    showCouponInput() {
      this.isShowCouponInput = true;
    },
    confirmPayment() {
      if (this.selectedPackage.isMock) {
        this.$message.error("please select a purchase plan");
        return false;
      }
      /* Prevent repeated clicks */
      if (this.duringPayment) {
        return false;
      }
      if (this.checkAuth()) {
        this.duringPayment = true;
        this.payOrder();
      } else {
        if (this.checkPurchaseForm()) {
          this.duringPayment = true;
          this.registerAndPayOrder();
        }
      }
    },
    updateSelectedPackage(paymentPackage) {
      this.selectedPackage = paymentPackage;
    },
    payOrder() {
      this.$axios
        .post("/v1/order", {
          packageId: this.selectedPackage.id,
          paymentMethod: this.currentPaymentMethod,
          webDomain: this.GLOBAL.getWebDomain(),
          terminal: this.GLOBAL.isMobile() ? "wap" : "pc",
        })
        .then((response) => {
          this.afterPayOrder(response.data.detail);
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.duringPayment = false;
        });
    },
    registerAndPayOrder() {
      let postData = {
        email: this.email,
        password: this.password,
        packageId: this.selectedPackage.id,
        paymentMethod: this.currentPaymentMethod,
        webDomain: this.GLOBAL.getWebDomain(),
      };
      let inviteNumber = this.$storage.get("inviterNumber");
      if (inviteNumber !== null) {
        postData.inviterNumber = inviteNumber;
        postData.inviterType = "NORMAL";
      }
      this.$axios
        .post("/auth/register-package", postData)
        .then((response) => {
          this.afterPayOrder(response.data.detail);
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.duringPayment = false;
        });
    },
    afterPayOrder(detail) {
      if (this.currentPaymentMethod !== "stripe") {
        if (detail.approval) {
          window.location.href = detail.approval;
        }
      } else {
        this.stripeCardCheckout(detail.id);
      }
      this.duringPayment = false;
    },
    checkPurchaseForm() {
      if (this.email === null) {
        this.$message.error("please input email");
        return false;
      }
      if (this.password === null || !this.checkPassword()) {
        this.$message.error("please enter the password as required");
        return false;
      }
      return true;
    },
    stripeCardCheckout(orderId) {
      // eslint-disable-next-line no-undef
      stripe
        .redirectToCheckout({
          sessionId: orderId,
        })
        .then(function (response) {
          console.log(response);
        });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.pricing {
  display: flex;
  flex-direction: column;
}

.platform-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.platform-content-box {
  flex: 1;
  max-width: 1160px;
  padding: 36px 30px 29px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  flex-wrap: wrap;
}

.step-1-left {
  margin: 15px 0;
}

.step-title-row {
  line-height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.step-btn {
  width: 60px;
  height: 24px;
  background: #1d1d1d;
  opacity: 1;
  border-radius: 24px;
  line-height: 24px;
  padding: 0 10px;
  color: #ffffff;
  font-size: 14px;
  margin-right: 5px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}

.step-title h1,h2,h3{
  font-size: 30px;
  font-weight: bold;
  line-height: 37px;
  color: #1d1d1d;
}


.step-tip {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #a8a8a8;
  margin-top: 9px;
}

.platform-row {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
}

.platform-icon {
  width: 40px;
  height: 40px;
  margin: 5px 20px;
}

.vertical-line {
  height: 40px;
  border: 1px solid #1d1d1d;
  opacity: 0.3;
  margin: 5px 20px;
}

.platform-ios-box {
  position: relative;
}

.download-ios-tip {
  width: 88px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -6px;
  left: 64px;
  text-align: center;
}

.step-1-right {
  margin: 15px 0;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 20px;
  color: #1d1d1d;
}

.days-icon {
  width: 84px;
  height: 84px;
}

.step-1-right-title {
  max-width: 128px;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: #1d1d1d;
}

.plan-area {
  padding-top: 65px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}

.coupon-container {
  width: 100%;
  max-width: 1140px;
  display: flex;
  flex-direction: column;

  display: none;
}

.coupon-title {
  margin: 30px 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #4ebe86;
}

.input-box {
  margin: 0 16px;
  max-width: 380px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  position: relative;
}

.input-space {
  margin-top: 15px;
}

.input-email {
  height: 19px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #141923;
  opacity: 0.8;
}

.input-tip {
  position: absolute;
  left: 15px;
  top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  color: #4ebe86;
  opacity: 0.8;
  z-index: 1;
}

.step-2 {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.step-2-content-box {
  flex: 1;
  max-width: 1160px;
  padding: 38px 30px 29px;
  display: flex;
  flex-direction: column;
}

.step-2-tip {
  max-width: 437px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #a8a8a8;
}

.create-account-input-box {
  max-width: 658px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e3e6ec;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #666666;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.show-or-hide-pwd-icon {
  width: 20px;
}

.email-tip {
  margin-top: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #ff0000;
}

.password-tip {
  margin-top: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.show-or-hide-password {
  margin-right: 20px;
  line-height: 60px;
}

.password-tip span {
  margin-right: 10px;
}

.password-tip div {
  margin-right: 16px;
}

.step-3 {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.step-3-box {
  flex: 1;
  max-width: 1160px;
  padding: 64px 30px 97px;
  display: flex;
  flex-direction: column;
}

.step-3-content-box {
  display: flex;
  justify-content: space-between;
  margin-top: 31px;
  flex-wrap: wrap;
}

.payment {
  width: 100%;
  max-width: 658px;
}

.payment-box {
  width: 100%;
  max-width: 658px;
  background: rgba(78, 190, 134, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.payment-item {
  width: 108px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #8260fc;
  border-color: rgba(20, 81, 154, 0);
}

.payment-item-card {
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
}

.payment-icon-alipay {
  width: 87px;
  height: 31px;
}

.payment-icon-paypal {
  width: 95px;
  height: 26px;
}

.payment-icon-card {
  max-width: 100%;
  height: auto;
}

.payment-item-right-space {
  margin-right: 10px;
}

.payment-item-left-space {
  margin-left: 10px;
}

.selected-payment-method {
  border: 2px solid #8260fc;
  opacity: 1;
  border-radius: 8px;
}

.payment-product-line {
  max-width: 646px;
  height: 0;
  border: 1px dashed #a8a8a8;
  opacity: 1;
  margin: 0 6px;
}

.order-box {
  max-width: 658px;
  background: rgba(78, 190, 134, 0.05);
  border-radius: 8px;
  overflow: hidden;
}

.order-info-line {
  max-width: 618px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 23px;
}

.order-info-line-bottom-space {
  margin-bottom: 24px;
}

.order-info-line-top-space {
  margin-top: 30px;
}

.line-through {
  text-decoration: line-through;
}

.color-red {
  color: #ff0000;
}

.text-bold {
  font-weight: bold;
}

.order-total {
  font-size: 32px;
  font-weight: bold;
  line-height: 39px;
}

.purchase-tip {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #a8a8a8;
  opacity: 1;
  margin-top: 24px;
}

.purchase-tip span {
  color: #4ebe86;
}

.confirm-btn {
  width: 100%;
  max-width: 658px;
  height: 56px;
  margin-top: 32px;
}

.confirm-btn span {
  font-weight: bold;
  font-family: Arial;
  font-size: 16px;
}

.premium-includes {
  margin-top: 20px;
  padding: 29px 25px;
  max-width: 330px;
  background-color: #ffffff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.premium-includes-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: #1d1d1d;
}

.premium-includes-line {
  margin-bottom: 18px;
  display: flex;
}

.premium-includes-description {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #1d1d1d;
}

.premium-includes-line:nth-child(1) {
  margin-top: 20px;
}

.icon-correct {
  width: 16px;
  height: 16px;
}

.icon-pricing-star {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

.create-account-input-box :deep(.el-input__inner:focus) {
  border-color: #ff0000;
}

.btn-color-1:hover {
  background: #72d4a3;
}
</style>
